<template>
  <ModalDrawer
    visible
    :title="t('title')"
    hide-footer
    @hide="emit('hide')"
  >
    <ZTipbox
      v-if="errorMessage"
      variant="danger"
    >
      {{ errorMessage }}
    </ZTipbox>

    <ZForm @submit.prevent="onSubmit">
      <ZFormGroup
        :label="t('textAreaLabel')"
        :is-invalid="v$.reportMessageComments.$error"
        :invalid-feedback="invalidFeedback"
      >
        <ZFormTextarea
          id="report-message-comments"
          v-model="reportMessageComments"
          :placeholder="t('textAreaPlaceholder')"
          :rows="isMobile ? 6 : 4"
          :max-rows="6"
        />
      </ZFormGroup>

      <I18nT
        keypath="viewTerms"
        tag="div"
        class="terms"
      >
        <NuxtLink
          target="_blank"
          :to="localePath('terms-service')"
        >
          {{ t('pageLink.termsOfService') }}
        </NuxtLink>
      </I18nT>

      <div class="button-container">
        <ZButton
          link
          variant="primary"
          class="cancel-button"
          @click="emit('hide')"
        >
          {{ t('actions.cancel') }}
        </ZButton>
        <ZButton
          type="submit"
        >
          {{ t('sendBtn') }}
        </ZButton>
      </div>
    </ZForm>
  </ModalDrawer>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { maxLength, minLength, required } from '@vuelidate/validators'
import type { BackendErrorInterface } from '~/types/backend-api-errors'
import type { Nullable } from '~/types'

const props = defineProps<{
  reviewId: number
}>()

const emit = defineEmits<{
  hide: []
}>()

const { t } = useI18n()
const localePath = useLocalePath()
const { isMobile } = useDevice()

const isLoading = ref(false)
const errorMessage = ref<Nullable<string>>(null)

const reportMessageComments = ref('')

const rules = {
  reportMessageComments: { required, minLength: minLength(40), maxLength: maxLength(400) },
}

const v$ = useVuelidate(rules, {
  reportMessageComments,
})

const invalidFeedback = computed(() => {
  if (v$.value.reportMessageComments.minLength.$invalid) {
    return t('errors.minimumLength', { length: v$.value.reportMessageComments.minLength.$params.min })
  }
  if (v$.value.reportMessageComments.maxLength.$invalid) {
    return t('errors.maximumLength', { length: v$.value.reportMessageComments.maxLength.$params.max })
  }

  return t('errors.required')
})

const { $rentals, $captureError } = useNuxtApp()

const { addToast } = useToast()
function sent() {
  emit('hide')

  addToast({ message: t('reportSentSuccessfully'), variant: 'success' })
}

async function onSubmit() {
  errorMessage.value = null

  if (isLoading.value) {
    return
  }

  if (v$.value.$invalid) {
    v$.value.$touch()
    return
  }

  isLoading.value = true

  try {
    await $rentals('/api/report/review-photo', {
      method: 'POST',
      body: {
        Message: reportMessageComments.value,
        ReviewId: props.reviewId,
        Type: 'Soliciting',
      },
    })

    sent()
  }
  catch (error) {
    const errorCast = error as BackendErrorInterface
    if (errorCast.status === 401) {
      errorMessage.value = t('errorLogin')
    }
    else {
      errorMessage.value = t('error')
    }

    $captureError(error)
  }
  finally {
    isLoading.value = false
  }
}
</script>

<style scoped lang="scss">
.button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.terms {
  @include caption;
  color: getColor('primary-350');
  text-align: end;
  padding-bottom: 1.25rem;
  margin-top: 0.5rem;

  :deep(a) {
    color: getColor('primary-350');
    font-weight: 600;
  }
}
.z-form-group {
  margin-bottom: 0;

  :deep(.field-label) {
    @include strong-1;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "title": "Why are you reporting a photo?",
    "textAreaLabel": "Provide specific details",
    "textAreaPlaceholder": "Describe why this photo should be reported",
    "sendBtn": "Send Report",
    "viewTerms": "View our {0}",
    "error": "Failed to submit the report. Please try again.",
    "errorLogin": "You must be logged in to report a photo.",
    "reportSentSuccessfully": "Report sent successfully"
  },
  "fr": {
    "title": "Pourquoi signalez-vous une photo?",
    "textAreaLabel": "Fournir des détails spécifiques",
    "textAreaPlaceholder": "Décrivez pourquoi cette photo doit être signalée",
    "sendBtn": "Envoyer le rapport",
    "viewTerms": "Voir nos {0}",
    "error": "Échec de la soumission du rapport. Veuillez réessayer.",
    "errorLogin": "Vous devez être connecté pour signaler une photo.",
    "reportSentSuccessfully": "Rapport envoyé avec succès"
  }
}
</i18n>
